$(document).ready(function() {
  $('.show-ul').on('click', function() {
    let li = $('.category-child').has(this)
    li.find('.list-child-1').show()
    li.find('.show-ul').hide()
    li.find('.hidden-ul').show()

  });
  $('.hidden-ul').on('click', function() {
    let li = $('.category-child').has(this)
    li.find('.list-child-1').hide()
    li.find('.show-ul').show()
    li.find('.hidden-ul').hide()
  });
  $('.show-ul-1').on('click', function() {
    let li = $('.category-child-1').has(this)
    li.find('.list-child-2').show()
    li.find('.show-ul-1').hide()
    li.find('.hidden-ul-1').show()

  });
  $('.hidden-ul-1').on('click', function() {
    let li = $('.category-child-1').has(this)
    li.find('.list-child-2').hide()
    li.find('.show-ul-1').show()
    li.find('.hidden-ul-1').hide()
  });
  $('.sidebar-bg-dark, .cancel').on('click', function() {
    let activeMenu = $('.menu-active')
    activeMenu.toggleClass('fate-in')
    activeMenu.toggleClass('fate-out')
    $('.sidebar-bg-dark').hide()
  });
  $('.active-list').on('click', function() {
    let activeMenu = $('.menu-active')
    activeMenu.removeClass('fate-out')
    activeMenu.toggleClass('fate-in')
    $('.sidebar-bg-dark').show()
  });
  let typingTimer;
  const doneTypingInterval = 1150;
  const input_pc = $('#q');
  const input_mb = $('#q-mobile');
  let startTypingTime;
  function initializeInput(inputElement) {
    inputElement.on('input', function() {
      var input_search = $(this) 
      clearTimeout(typingTimer);
      if (!startTypingTime) {
        startTypingTime = new Date().getTime();
      }
      if (inputElement.val().trim() !== "") {
        typingTimer = setTimeout(function() {
          const currentTime = new Date().getTime();
          if (currentTime - startTypingTime >= doneTypingInterval) {
              performSearch(input_search);
          }
        }, doneTypingInterval);
      }
    });
  }
  // inputElement.on('keydown', function(event) {
  //   if (event.which === 32) {
  //     clearTimeout(typingTimer);
  //     performSearch($(this));
  //   }
  // });

  function performSearch(input) {
    // var keycode = (event.keyCode ? event.keyCode : event.which);
    // if (keycode == 32) {
    let lists = $(".search-list")
    let search = input.val()
    if (search.length > 0){
      $.ajax({
      url: '/search_api',
      method: 'GET',
      data: {
          q: search,
        }
      }).then(function(res) {
        $(lists[0]).empty()
        $(lists[1]).empty()
          if (res.length === 0) {
            $(".search-list").html('không tìm thấy sản phẩm')
          }
        $.each(res, function( index, prd ) {
          let li =
            "<li class='li-item'>" +
            "<a href='" + prd.url + "'>" +
            "<img src='" + prd.image + "' alt='item image' class='first-content'>" +
            "<div class='last-content'>" +
            "<div class='name'>" + prd.name + "</div>" +
            "<div class='price'>" + prd.price + "</div>" +
            "</div>"+
            "</a>"+
            "</li>"
          $(lists[0]).append(li)
          $(lists[1]).append(li)
        })
      })
      // } else {
      //   $(".search-list").html('')
      // }
    }
  };
  initializeInput(input_pc)
  initializeInput(input_mb)
  $('.search-form-js').focusout(function() {
    if (!$('.search-list:hover').length){
      $(".search-list").html('')
    }
  });
  $("#q").attr("autocomplete", "off");
});